.closeButton {
    background-color: #edf2f7;
    padding: 0.5rem;
    font-size: 1.25rem;
    color: #718096;
    width: 2rem;
    height: 2rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.detailsPanel {
    z-index: 10001;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.detailsPanelContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100vh;
    background-color: rgb(255 255 255);
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    z-index: 10000;
    overflow: scroll;
}