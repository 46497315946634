.closeButton {
  color: #718096;
  background-color: #edf2f7;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: .5rem;
  font-size: 1.25rem;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  display: flex;
}

.detailsPanel {
  z-index: 10001;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.detailsPanelContainer {
  z-index: 10000;
  background-color: #fff;
  border-radius: .5rem;
  width: 50%;
  height: 100vh;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  overflow: scroll;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
}
/*# sourceMappingURL=index.8bae7bbb.css.map */
